
/*** Message box ***/
div.message-box-wrap{
  flex-direction: column;
  width:280px;
  height: 100px;
  position: fixed;
  bottom: 20px;
  left:0;
  background-color: #fdfdfd;
  z-index: 8000;
  box-sizing: border-box;
  border: 1px solid var(--color-border);
  box-shadow: 2px 3px 2px  rgba(18,20,30,.08);
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
div.message-box-wrap.active{
  animation-name: slideInMessage;
  animation-duration: .3s;
  animation-timing-function: ease-out;
}
div.message-box-wrap.inactive{
  animation-name: slideOutMessage;
  animation-duration: .3s;
  animation-timing-function: ease-out;
  left:-280px;
}

@keyframes slideInMessage {
  from {
     left:-280px;
  }
  to {
    left:0;
  }
}
@keyframes slideOutMessage {
  from {
     left:0;
  }
  to {
    left:-280px;
  }
}


div.message-box-header{
  height: 20px;
  width: 100%;
  font-size: .8em;
  border-top: 4px solid #77529e;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
}
div.message-box-header.error{
  border-top: 4px solid var(--color-error);
}
div.message-box-header div.close{
  cursor: pointer;
}
div.message-content{
  padding: 5px 10px;
  font-size:.9em;
  color:#333;
}
